<template>
	<div class="container">
		<div class="title">
			<div class="text">工程车类型</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">图标</div>
					<div class="col flex-1">中文名称</div>
					<div class="col flex-1">维文名称</div>
					<div class="col flex-1">哈文名称</div>
					<div class="col flex-1">排序</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in dataList" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col avatar">
						<img class="avatar-img no-radius" :src="item.photo" :onerror="defaultImg" alt="" />
					</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1">{{ item.name_ug }}</div>
					<div class="col flex-1">{{ item.name_kz }}</div>
					<div class="col flex-1">{{ item.sort }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-title="item.name || 'id:'+item.id"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>

<script>
	let standById = 0;
	import {
		getTruckTypeList,
		deleteTruckType
	} from "@/api/agent";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox,
		},
		data() {
			return {
				dataList: [{
					id:1,
					name:"挖掘机",
					name_ug:"挖掘机",
					name_kz:"挖掘机",
					sort:1,
					photo:""
				},{
					id:1,
					name:"挖土机",
					name_ug:"挖土机",
					name_kz:"挖土机",
					sort:1,
					photo:""
				}],
				filter: {
					status: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			}
		},
		created() {
			// this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "EngineeringCarTypeAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "EngineeringCarTypeUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				if (this.filter.status == -1) {
					this.filterData = {
						page: this.filterData.page,
						size: this.filterData.size
					}
				} else {
					this.filterData["status"] = this.filter.status;
				}
				getTruckTypeList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data;
						// this.totalRecords = res.data.total
						this.totalRecords = 1
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteTruckType(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
